<template>
    <SimpleSlider
        :items-count="itemsCount"
        :additional-scroll-offset="0"
        class="product-simple-slider"
        :class="{ 'with-heading': heading }"
        :has-navigation-buttons="hasNavigationButtons"
        :change-slide-by-one="false"
        @navigation-button-click="emitScrollEvent()"
        @on-touch-end="emitScrollEvent()"
    >
        <template #slides>
            <ProductsSimpleSliderSlide
                v-for="(product, index) in products"
                :key="`products-simple-slider-slide-${product.sku}-${index}`"
                :product="product"
                :items-count="itemsCount"
                :action-field="actionField"
                :[PRODUCT_SLIDER_SLIDE_ATTRIBUTE]="index"
                :index="index"
                @product-click="$emit('product-click', { ...$event, index })"
            />
        </template>
    </SimpleSlider>
</template>

<script>
import { mapState } from 'vuex';

import { ITEMS_COUNT } from '@configs/simple-slider';
import { PRODUCT_SLIDER_SLIDE_ATTRIBUTE } from '@configs/product';

import { SIZE_SMALL } from '@types/Button';

import ProductsSimpleSliderSlide from '@molecules/ProductsSimpleSliderWrapper/ProductsSimpleSliderSlide';
import SimpleSlider from '@molecules/SimpleSlider/SimpleSlider';

export default {
    name: 'ProductSimpleSlider',

    components: {
        SimpleSlider,
        ProductsSimpleSliderSlide,
    },

    props: {
        products: {
            type: Array,
            default: () => [],
        },

        itemsCount: {
            type: Number,
            default: 6,
            validator: value => ITEMS_COUNT.includes(value),
        },

        heading: {
            type: String,
            default: '',
        },

        actionField: {
            type: String,
            default: '',
        },
    },

    computed: {
        ...mapState(['isMobile']),

        hasNavigationButtons() {
            return this.products.length > this.itemsCount;
        },
    },

    beforeCreate() {
        this.PRODUCT_SLIDER_SLIDE_ATTRIBUTE = PRODUCT_SLIDER_SLIDE_ATTRIBUTE;
        this.SIZE_SMALL = SIZE_SMALL;
    },

    methods: {
        emitScrollEvent() {
            this.$emit('scroll');
        },
    },
};
</script>

<style lang="scss" scoped>
$product-card-description-height: 139px;
$navigation-buttons-height: 42px;

$navigation-top: calc(
    (100% - #{$product-card-description-height}) / 2 - #{$navigation-buttons-height / 2}
);

.product-simple-slider {
    @apply w-full;
    &.with-heading {
        @apply mt-2;
    }

    @screen lg {
        &.with-heading {
            @apply mt-12p;
        }

        &:deep() {
            .navigation-buttons {
                &.horizontal-nav {
                    top: $navigation-top;
                    width: calc(100% - 2 * #{$tailwindcss-customSizes-20p});
                    margin-left: $tailwindcss-spacing-3;
                }
            }
        }
    }
}
</style>
