import Product from '@models/Catalog/Product';
import { ProductBadge } from '@models/ProductBadge/ProductBadge';

import { filterOutDiscountBadges } from '@assets/product-badge';

export const getProductBadgeConfig = ({
    productBadges,
    productPrice,
    isDiscountEnabled,
    isOmnibusEnabled,
    $t,
}) => {
    const {
        discount,
        promotional: { amount: promotionalAmount },
        regular: { amount: regularAmount },
        omnibus: { amount: omnibusAmount } = {},
        isMinimal = false,
    } = productPrice;

    const badges = filterOutDiscountBadges(productBadges);

    if (isMinimal || !isDiscountEnabled) {
        return badges;
    }

    const { badgeType, badgeContent } = Product.getDiscountBadge({
        discount,
        regularAmount,
        omnibusAmount,
        promotionalAmount,
        isOmnibusStrict: isOmnibusEnabled,
        $t,
    });

    if (badgeType) {
        badges.push(
            new ProductBadge({
                type: badgeType,
                content: badgeContent,
            }).getPlainObject()
        );
    }

    return badges;
};
